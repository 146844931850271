import { createStore } from 'vuex'

const uiValuesDefault = {
    userId: null,  // TODO: Needed for technical reasons
    sender: null,
    destination: null,
    content: null,
    jbEventDefinitionKey: null
}

export const store = createStore({
    state () {
        return {
            step: {},
            jbPayload: {
                arguments: {
                    execute: {
                        inArguments: [],
                        outArguments: [],
                    },
                    testExecute: {
                        inArguments: [],
                        outArguments: [],
                    },
                },
                schema: {
                    arguments: {
                        execute: {
                            inArguments: [],
                            outArguments: [],
                        },
                        testExecute: {
                            inArguments: [],
                            outArguments: [],
                        },
                    },
                },
                metaData: undefined

            },
            jbInteractionPayload: {},
            jbInteractionDefaults: {},
            jbTriggerEventDefinition: {},
            jbEndpoints: {},
            jbTokens: {
                fuel2token: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ACCESS_TOKEN : null,
            },
            jbSchema: [],
            uiValues: uiValuesDefault,
            lookups: {
            },
        }
    },
    mutations: {
        // Update the current wizard step
        updateStep (state, value) {
            state.step = value
        },

        // Update the configured name for the custom activity
        updateJbActivityName (state, value) {
            state.jbPayload.name = value
        },

        // Reset UI Values to default values.
        resetUIValues (state) {
            state.uiValues = uiValuesDefault
        },

        // -------------------------------------
        // -- Postmonger responses Setters
        // -- When the jb-custom-activity mixin triggers some postmonger events, the responses are saved in store.
        // -------------------------------------
        updateJbPayload (state, value) {
            state.jbPayload = value
            if (state.jbPayload.metaData && state.jbPayload.metaData.uiValues) {
                state.uiValues = state.jbPayload.metaData.uiValues
            }
        },
        updateUIValues (state, value) {
            state.uiValues = value
        },
        updateJbEndpoints (state, value) {
            state.jbEndpoints = value
        },
        updateJbTokens (state, value) {
            state.jbTokens = value
        },
        updateJbSchema (state, value) {
            state.jbSchema = value
        },
        updateJbCulture (state, value) {
            state.jbCulture = value
        },
        updateJbInteractionPayload (state, value) {
            state.jbInteractionPayload = value
        },
        updateJbInteractionDefaults (state, value) {
            state.jbInteractionDefaults = value
        },
        updateJbTriggerEventDefinition (state, value) {
            state.jbTriggerEventDefinition = value
        },

        // -------------------------------------
        // -- UI Value Setters
        // -------------------------------------
        updateFieldUserId (state, value) {
            state.uiValues.userId = value
        },

        updateFieldSender (state, value) {
            state.uiValues.sender = value
        },

        updateFieldDestination (state, value) {
            state.uiValues.destination = value
        },

        updateFieldContent (state, value) {
            state.uiValues.content = value
        },

        updateFieldJbEventDefinitionKey (state, value) {
            state.uiValues.jbEventDefinitionKey = value
        },

        inArgumentsClear (state) {
            if (!state.jbPayload.schema.arguments.testExecute) {
                state.jbPayload.schema.arguments.testExecute = {
                    inArguments: [],
                }
            }
            if (!state.jbPayload.arguments.testExecute) {
                state.jbPayload.arguments.testExecute = {
                    inArguments: [],
                }
            }

            state.jbPayload.schema.arguments.execute.inArguments = []
            state.jbPayload.schema.arguments.testExecute.inArguments = []
            state.jbPayload.arguments.execute.inArguments = []
            state.jbPayload.arguments.testExecute.inArguments = []
        },

        inArgumentAdd (state, value) {
            const schemaInArgument = {
                [value.key]: {
                    dataType: value.dataType,
                    isNullable: value.isNullable,
                    direction: 'in',
                    access: 'Visible',
                },
            }
            state.jbPayload.schema.arguments.execute.inArguments.push(schemaInArgument)
            state.jbPayload.schema.arguments.testExecute.inArguments.push(schemaInArgument)

            const inArgument = {
                [value.key]: value.value,
            }
            state.jbPayload.arguments.execute.inArguments.push(inArgument)
            state.jbPayload.arguments.testExecute.inArguments.push(inArgument)
        },

        inArgumentRemove (state, value) {
            const index = state.jbPayload.arguments.execute.inArguments.findIndex(o => o.name === value.name)
            if (index !== -1) {
                state.jbPayload.arguments.execute.inArguments.splice(index, 1)
            }
        },

        inArgumentFromSchema (state) {
            // console.log('Getting inArgument object from journey schema', state.jbPayload.schema, value)
            if (state.jbPayload.schema) {
                // state.jbPayload.schema.forEach((schemaItem) => {
                //   if (schemaItem.key.indexOf('Event.') >= 0) {
                //     const key = schemaItem.key.substr(schemaItem.key.lastIndexOf('.') + 1).toLowerCase()
                //     const expression = schemaItem.key
                //     const dataType = schemaItem.type
                //     const isNullable = schemaItem.isPrimaryKey ? false : schemaItem.isNullable
                //     const item = {
                //       key,
                //       expression,
                //       dataType,
                //       isNullable,
                //       direction: 'in',
                //       access: 'Visible',
                //     }
                //     console.log('Schema in argument', item)
                //   }
                // })
            }
        },
    },
})

export default store