// ========================================================
// == Journey Builder Custom Activity Mixin              ==
// ========================================================
//
// This mixin is to be used in the main component. It encapsulates most of the app logic involving Journey Builder.
// The mixin declares a global event bus $jb, which can be used to trigger postmonger events and to attach listeners to postmonger events.
// - It uses the Vue Lifecycle methods to populate data from postmonger into the app $store and to trigger postmonger events.
// - When the app starts, the following event chain is executed. They must be chained because each method requires data from the previous one's response.
//   - On Vue lifecycle "Created" method, postmonger is initialized and the postmonger "ready" event is triggered.
//   - On the "onReady" listener, the correspondent "onInitActivity" method is executed, which triggers the postmonger "requestEndpoints" event.
//   - On the "onRequestedEndpoints" listener, the "requestTokens" method is executed, which triggers the rest of the postmonger data retrieval calls.
//   - On each of the listener responses the data received from postmonger is stored in the app $store for later usage.

import Postmonger from 'postmonger' // Required for Custom Activities
import jbCustomActivityHTTP from '@/mixins/jb-custom-activity-http'

const baseURL = process.env.VUE_APP_BACKEND_URL.replace(/\/+$/, '')

export default {
    components: { Postmonger },
    mixins: [jbCustomActivityHTTP],
    data: () => ({
        jbJourneyDataContext: {},
        postmonger: null,
        mapInArguments: {},
        mapOutArguments: {},
        mapSchemaInArguments: {},
        mapSchemaOutArguments: {},
    }),

    /*
     * The Custom Activity mixin uses $store for state management.
     * All accesses to $store are encapsulated in computed properties.
     */
    computed: {
        step: {
            get () {
                return this.$store.state.step
            },
            set (value) {
                this.$store.commit('updateStep', value)
            },
        },

        accessToken () {
            return this.$store.state.jbTokens.fuel2token
        },

        jbPayload: {
            get () {
                return this.$store.state.jbPayload
            },
            set (value) {
                this.$store.commit('updateJbPayload', value)
            },
        },

        uiValues: {
            get () {
                return this.$store.state.uiValues
            },
            set (value) {
                if (!value) {
                    this.$store.commit('resetUIValues')
                } else {
                    this.$store.commit('updateUIValues', value)
                }
            },
        },

        jbInteractionPayload: {
            get () {
                return this.$store.state.jbInteractionPayload
            },
            set (value) {
                this.$store.commit('updateJbInteractionPayload', value)
            },
        },

        jbEndpoints: {
            get () {
                return this.$store.state.jbEndpoints
            },
            set (value) {
                this.$store.commit('updateJbEndpoints', value)
            },
        },

        jbTokens: {
            get () {
                return this.$store.state.jbTokens
            },
            set (value) {
                this.$store.commit('updateJbTokens', value)
            },
        },

        jbSchema: {
            get () {
                return this.$store.state.jbSchema
            },
            set (value) {
                this.$store.commit('updateJbSchema', value)
            },
        },

        jbCulture: {
            get () {
                return this.$store.state.jbCulture
            },
            set (value) {
                this.$store.commit('updateJbCulture', value)
            },
        },

        jbInteractionDefaults: {
            get () {
                return this.$store.state.jbInteractionDefaults
            },
            set (value) {
                this.$store.commit('updateJbInteractionDefaults', value)
            },
        },

        jbTriggerEventDefinition: {
            get () {
                return this.$store.state.jbTriggerEventDefinition
            },
            set (value) {
                this.$store.commit('updateJbTriggerEventDefinition', value)
            },
        },
    },

    methods: {
        /**
         * @description This the callback for the "ready" postmonger event.
         * On success it triggers "requestEndpoints" postmonger call.
         */
        onInitActivity (initialPayload) {
            this.$log.info('Config Ready - payload received', initialPayload)
            this.jbPayload = initialPayload
            if (initialPayload.metaData.isConfigured) {
                this.$log.debug('The activity was already configured')
                this.uiValues = this.jbPayload.metaData.uiValues
            } else {
                this.$log.debug('The activity was not previously configured')
                this.uiValues = null
            }

            this.$log.debug('The activity was not previously configured')
            this.postmonger.trigger('requestEndpoints')
            this.postmonger.trigger('updateButton', {
                button: 'next',
                text: 'done',
                enabled: false,
            })
        },

        /**
         * @description This the callback for the "ready" postmonger event.
         * On success it triggers "requestEndpoints" postmonger call.
         */
        onInitActivityHover (initialPayload) {
            this.$log.info('Hover Ready - payload received', initialPayload)
            this.jbPayload = initialPayload
            if (initialPayload.metaData.isConfigured) {
                this.uiValues = this.jbPayload.metaData.uiValues
            }
            this.postmonger.trigger('requestEndpoints')
        },

        /**
         * @description This the callback for the "ready" postmonger event.
         * On success it triggers "requestEndpoints" postmonger call.
         */
        onInitActivityModal (initialPayload) {
            this.$log.info('Modal Ready - payload received', initialPayload)
            this.jbPayload = initialPayload
            if (initialPayload.metaData.isConfigured) {
                this.uiValues = this.jbPayload.metaData.uiValues
            }
            this.postmonger.trigger('requestEndpoints')
        },

        /**
         * @description This the callback for the "ready" postmonger event.
         * On success it triggers "requestEndpoints" postmonger call.
         */
        onInitActivityEvent (data) {
            this.$log.info('Event Ready - payload received', data)
            this.jbPayload = data
            if (data.metaData.isConfigured) {
                this.uiValues = this.jbPayload.metaData.uiValues
            }
            this.postmonger.trigger('requestEndpoints')
        },

        /**
         * @description This the callback for the "requestEndpoints" postmonger event.
         * Save the received data (the API endpoint URLs) in the app store.
         * On success it triggers "requestTokens" postmonger call.
         */
        onRequestedEndpoints (jbEndpoints) {
            this.$log.info('onRequestedEndpoints', jbEndpoints)
            this.jbEndpoints = jbEndpoints
            this.postmonger.trigger('requestTokens')
        },

        /**
         * @description This the callback for the "requestTokens" postmonger event.
         * On success it triggers the "requestEndpoints", "requestSchema", "requestCulture", "requestInteractionDefaults", "requestInteraction", "requestTriggerEventDefinition" postmonger calls.
         * With the received payload, it uses the REST API to get the context of the OAuth token in order to obtain the current MID. With this MID it completes all the backend URLs in config.json.
         */
        onRequestedTokens (jbTokens) {
            this.$log.info('onRequestedTokens', jbTokens)
            this.jbTokens = jbTokens

            this.postmonger.trigger('requestSchema')
            this.postmonger.trigger('requestCulture')
            this.postmonger.trigger('requestInteractionDefaults')
            this.postmonger.trigger('requestInteraction')
            this.postmonger.trigger('requestTriggerEventDefinition')

            // Send a tokenContext request to retrieve userId, mid and eid. Then, set the MID in all the custom activities config urls.
            if (jbTokens.fuel2token) {
                this.getTokenContextREST(jbTokens.fuel2token, this.jbEndpoints.fuelapiRestHost)
                    .then((response) => {
                        this.jbTokens.tokenContext = response.data
                        const mid = response.data.organization.id
                        this.uiValues.userId = response.data.user.id
                        this.jbPayload.configurationArguments.validate.url = `${baseURL}/activity/${mid}/validate`
                        this.jbPayload.configurationArguments.publish.url = `${baseURL}/activity/${mid}/publish`
                        this.jbPayload.arguments.execute.url = `${baseURL}/activity/${mid}/execute`
                        //this.jbPayload.configurationArguments.save.url = `${baseURL}/activity/${mid}/save`
                        //this.jbPayload.configurationArguments.testSave.url = `${baseURL}/activity/${mid}/test-save`
                        //this.jbPayload.configurationArguments.testValidate.url = `${baseURL}/activity/${mid}/test-validate`
                        //this.jbPayload.configurationArguments.testPublish.url = `${baseURL}/activity/${mid}/test-publish`
                        //this.jbPayload.arguments.testExecute.url = `${baseURL}/activity/${mid}/test-execute`
                        
                    })
            }
        },

        /**
         * @description This the callback for the "requestInteraction" postmonger event.
         * Save the received data (the entire Journey JSON payload) in the app store.
         */
        onRequestedInteraction (jbInteractionPayload) {
            this.$log.info('onRequestedInteraction', jbInteractionPayload)
            this.jbInteractionPayload = jbInteractionPayload
        },

        /**
         * @description This the callback for the "requestSchema" postmonger event.
         * Save the received data (the Journey Schema fields) in the app store.
         */
        onRequestedSchema (jbSchema) {
            this.$log.info('onRequestedSchema', jbSchema)
            this.jbSchema = jbSchema
            this.emitter.emit('caReady')
        },

        /**
         * @description This the callback for the "requestCulture" postmonger event.
         * Save the received data (the user language) in the app store.
         */
        onRequestedCulture (jbCulture) {
            this.$log.info('onRequestedCulture', jbCulture)
            this.jbCulture = jbCulture
        },

        /**
         * @description This the callback for the "requestInteractionDefaults" postmonger event.
         * Save the received data (the Journey default mappings - email, phone) in the app store.
         */
        onRequestedInteractionDefaults (jbInteractionDefaults) {
            this.$log.info('onRequestedInteractionDefaults', jbInteractionDefaults)
            this.jbInteractionDefaults = jbInteractionDefaults
        },

        /**
         * @description This the callback for the "requestTriggerEventDefinition" postmonger event.
         * Uses the SOAP API to retrieve the current trigger's Data Extension external key, if present.
         * Save the received data (the Journey configured entry event) in the app store.
         */
        onRequestedTriggerEventDefinition (jbTriggerEventDefinition) {
            this.$log.info('onRequestedTriggerEventDefinition', jbTriggerEventDefinition)
            this.jbTriggerEventDefinition = jbTriggerEventDefinition

            // Search for the configured entry DE and get its External Key
            this.dataExtensionSearchBySimpleAttribute(this.jbTokens.fuel2token, 'ObjectID', 'equals', jbTriggerEventDefinition.dataExtensionId)
                .then((response) => {
                    if (response && response.length === 1) {
                        jbTriggerEventDefinition.dataExtension = response[0] // eslint-disable-line
                    }
                })
        },

        /**
         * @description This the callback for the "updateActivity" postmonger event.
         */
        onUpdateActivity (data) {
            this.$log.info('onUpdateActivity', data)
        },

        /**
         * @description This the callback for the "updateEvent" postmonger event.
         */
        onUpdateEvent (data) {
            this.$log.info('onUpdateEvent', data)
        },

        /**
         * @description This the callback for the "updateButton" postmonger event.
         */
        onUpdatedButton (data) {
            this.$log.info('onUpdatedButton', data)
        },

        /**
         * @description This the callback for the "updateButton" postmonger event.
         * Save the received data (current activity wizard step) in the app store.
         */
        onGotoStep (step) {
            this.$log.info('onGotoStep', step)
            this.step = step
        },

        /**
         * @description This the callback for the "clickNext" postmonger event.
         */
        onClickedNext () {
            this.$log.info('onClickedNext')
            this.postmonger.trigger('nextStep')
        },

        /**
         * @description This the callback for the "clickBack" postmonger event.
         */
        onClickedBack () {
            this.$log.info('onClickedBack')
            this.postmonger.trigger('prevStep')
        },
    },


    /**
     * @description Before App Destroyed, emoves all methods declared in the JB event bus.
     */
    beforeDestroy () {
        this.$log.info('Removing all event bus methods.')
        this.emitter.off('getConnection')
        this.emitter.off('ready')
        this.emitter.off('updateButton')
        this.emitter.off('updateActivity')
        // this.emitter.off('updateEvent')
        this.emitter.off('save')
        this.emitter.off('clickNext')
        this.emitter.off('clickBack')
        this.emitter.off('gotoStep')
        this.emitter.off('nextStep')
        this.emitter.off('prevStep')
        this.emitter.off('requestTokens')
        this.emitter.off('requestEndpoints')
        this.emitter.off('requestSchema')
        this.emitter.off('requestCulture')
        this.emitter.off('requestInteraction')
        this.emitter.off('requestInteractionDefaults')
        this.emitter.off('requestTriggerEventDefinition') // only for events
        this.emitter.off('addInArgumentsFromSchema')
        this.emitter.off('argumentUpsert')
        this.emitter.off('destroy') // only for modal

        // Custom event (not from postmonger) sent from the app views when we are ready to start loading views/pages
        this.emitter.off('caReady')
    },

    /**
     * @description On App Created, initialize postmonger and map the $jb global events bus handlers
     */
    created () {
        this.$log.info('Initializing Postmonger')
        this.postmonger = new Postmonger.Session()
        const self = this

        this.emitter.on('caReady', function () { // eslint-disable-line
            self.$log.info('Initialization Finished')
        })

        this.emitter.on('getConnection', function () { // eslint-disable-line
            return self.postmonger
        })

        this.emitter.on('ready', function () { // eslint-disable-line
            self.postmonger.trigger('ready')
        })

        this.emitter.on('updateButton', function (data) { // eslint-disable-line
            self.postmonger.trigger('updateButton', data)
        })

        this.emitter.on('updateActivity', function (data) { // eslint-disable-line
            self.postmonger.trigger('updateActivity', data)
        })

        // this.emitter.on('updateEvent', function (data) { // eslint-disable-line
        //   self.postmonger.trigger('updateEvent', data)
        // })

        this.emitter.on('save', function () { // eslint-disable-line
            self.jbPayload.metaData.uiValues = self.uiValues
            self.jbPayload.metaData.isConfigured = true
            self.postmonger.trigger('updateActivity', self.jbPayload) // TODO: fix for custom JB events, cu
        })

        this.emitter.on('clickNext', function () { // eslint-disable-line
            self.postmonger.trigger('clickNext')
        })

        this.emitter.on('clickBack', function () { // eslint-disable-line
            self.postmonger.trigger('clickBack')
        })

        this.emitter.on('gotoStep', function (step) { // eslint-disable-line
            self.postmonger.trigger('gotoStep', step)
        })

        this.emitter.on('nextStep', function (step) { // eslint-disable-line
            self.postmonger.trigger('nextStep', step)
        })

        this.emitter.on('prevStep', function (step) { // eslint-disable-line
            self.postmonger.trigger('prevStep', step)
        })

        this.emitter.on('requestTokens', function () { // eslint-disable-line
            self.postmonger.trigger('requestTokens')
        })

        this.emitter.on('requestEndpoints', function () { // eslint-disable-line
            self.postmonger.trigger('requestEndpoints')
        })

        this.emitter.on('requestSchema', function () { // eslint-disable-line
            self.postmonger.trigger('requestSchema')
        })

        this.emitter.on('requestCulture', function () { // eslint-disable-line
            self.postmonger.trigger('requestCulture')
        })

        this.emitter.on('requestInteraction', function () { // eslint-disable-line
            self.postmonger.trigger('requestInteraction')
        })

        this.emitter.on('requestInteractionDefaults', function () { // eslint-disable-line
            self.postmonger.trigger('requestInteractionDefaults')
        })

        this.emitter.on('requestTriggerEventDefinition', function () { // eslint-disable-line
            self.postmonger.trigger('requestTriggerEventDefinition')
        })

        this.emitter.on('addInArgumentsFromSchema', function (schema) { // eslint-disable-line
            self.addInArgumentsFromSchema(schema)
        })

        this.emitter.on('argumentUpsert', function (argument) { // eslint-disable-line
            self.argumentUpsert(argument.key, argument.expression, argument.dataType, argument.isNullable, argument.direction, argument.access)
            self.argumentsRefresh()
        })

        this.emitter.on('destroy', function () { // eslint-disable-line
            self.postmonger.trigger('destroy')
        })
    },

    /**
     * @description Before App Mount, maps JB postmonger event handlers to local callbacks
     */
    beforeMount () {
        this.$log.info('Mapping postmonger events to local callbacks')

        // The following callbacks
        this.postmonger.on('initActivity', this.onInitActivity)
        this.postmonger.on('initActivityRunningHover', this.onInitActivityHover)
        this.postmonger.on('initActivityRunningModal', this.onInitActivityModal)
        this.postmonger.on('initEvent', this.onInitActivityEvent)
        this.postmonger.on('requestedTokens', this.onRequestedTokens) // Retrieves auth tokens for API calls
        this.postmonger.on('requestedEndpoints', this.onRequestedEndpoints) // Retrieves the endpoints for API calls
        this.postmonger.on('requestedSchema', this.onRequestedSchema) // Retrieves the schema JSON structure
        this.postmonger.on('requestedCulture', this.onRequestedCulture) // Retrieves the session's locale settings
        this.postmonger.on('requestedInteractionDefaults', this.onRequestedInteractionDefaults) // Retrieves the default values for contactKey, email address and phone number
        this.postmonger.on('updatedActivity', this.onUpdateActivity) // JB triggers this postmonger event the activity data is updated
        // this.postmonger.on('updatedEvent', this.onUpdateEvent) // JB triggers this postmonger event the activity data is updated
        this.postmonger.on('requestedInteraction', this.onRequestedInteraction) // Retrieves the interaction JSON structure
        this.postmonger.on('updatedButton', this.onUpdatedButton)
        this.postmonger.on('requestedTriggerEventDefinition', this.onRequestedTriggerEventDefinition)
        this.postmonger.on('clickedNext', this.onClickedNext) // Action of the next/done button
        this.postmonger.on('clickedBack', this.onClickedBack)
        this.postmonger.on('gotoStep', this.onGotoStep)
        this.postmonger.trigger('ready')
        this.$log.debug('postmonger.trigger(ready)')
    },
}
