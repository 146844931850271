<template>
      <v-form
          v-model="isFormValid"
          ref="formPageMain">
        <v-row justify="center">
      <v-col cols="10">
            <v-card>
              <v-toolbar
            color="blue"
                dark density="compact">
                <v-toolbar-title>Configuration</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-container >
                <!-- Sender Id -->
                <v-row align="center">
                  <v-col cols="6">
                    <v-autocomplete
                        prepend-inner-icon="mdi-cube-send"
                        readonly="true"
                        v-model="sender"
                        :items="senderIdItems"
                        dense
                        filled
                        class="mx-2"
                        label="Sender"
                        required
                        :rules="[rules.required]">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        prepend-inner-icon="mdi-cellphone-iphone"
                        readonly="true"
                        v-model="destination"
                        :items="destinationItems"
                        item-title="name"
                        item-value="expression"
                        dense
                        filled
                        class="mx-2"
                        label="Destination Phone"
                        placeholder="Start typing to Search"
                        required
                        :rules="[rules.required]">
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
              cols="10"
              sm="10"
              md="10">
            <v-card>
              <v-toolbar
              color="blue"
                  dark density="compact">

                <v-toolbar-title>Message</v-toolbar-title>

                <v-spacer></v-spacer>
              </v-toolbar>
              <v-container >
                <!-- Message Area -->
                <v-row align="center">
                  <v-col cols="12">
                    <v-textarea id="sfmcTextAreaVue"
                                readonly="true"
                                ref="textarea"
                                counter
                                prepend-inner-icon="mdi-comment"
                                class="mx-2"
                                label="Message Content"
                                rows="4"
                                v-model="content"
                                required
                                :rules="[rules.required]">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-form>      
    </template>

<script>
import helpers from '@/mixins/helpers'
import jbCustomActivityHTTP from '@/mixins/jb-custom-activity-http'

export default {
  name: 'PageMain',
  mixins: [helpers, jbCustomActivityHTTP],

  data: () => ({
    // Form state variables
    dialog: false,
    isFormValid: false,
    rules: {
      required: value => (!!value || value === false) || 'This field is mandatory.', // TODO: doesn't work in actual JB if it is the fist screen of the activity.
    },

    senderIdItems: process.env.VUE_APP_SMS_SENDER_IDS.split(','),
    destinationItems: [],
    variableItems: [],
    variableValue: null,
  }),

  mounted () {
    setTimeout(this.loadDataVariableItems, 3000);
    setTimeout(this.loadDataPhoneFieldItems, 3000);
    this.emitter.emit('ready')
  },

  computed: {
    accessToken () {
      return this.$store.state.jbTokens.fuel2token
    },

    sender: {
      get () {
        return this.$store.state.uiValues.sender
      },
      set (value) {
        this.$store.commit('updateFieldSender', value)
      },
    },

    destination: {
      get () {
        return this.$store.state.uiValues.destination
      },
      set (value) {
        this.$store.commit('updateFieldDestination', value)
      },
    },

    content: {
      get () {
        return this.$store.state.uiValues.content
      },
      set (value) {
        this.$store.commit('updateFieldContent', value)
      },
    },

    jbActivityKey: {
        get () {
            return this.$store.state.jbPayload.key || 'Undefined'
        }
    },

    jbEventDefinitionKey: {
      get () {
        return this.$store.state.uiValues.jbEventDefinitionKey
      },
      set (value) {
        this.$store.commit('updateFieldJbEventDefinitionKey', value)
      },
    },

    jbTriggerEventDefinition () {
      return this.$store.state.jbTriggerEventDefinition
    },

    jbSchema () {
      return this.$store.state.jbSchema
    },

  },

  methods: {
    /**
     * @description Button Next action. Calls the mapping view passing the DE ExternalKey as path parameter.
     */
    clickCancel () {
      this.emitter.emit('destroy')
    },

    /**
     * Get all input arguments from the journey schema and populate "variableItems"
     */
    loadDataVariableItems () {
      this.variableItems = [
        {
          key: 'ContactKey',
          expression: '{{Contact.Key}}',
          name: 'Journey Settings: Contact Key',
        },
        {
          key: 'Email',
          expression: '{{InteractionDefaults.Email}}',
          name: 'Journey Settings: Email Address',
        },
        {
          key: 'MobileNumber',
          expression: '{{InteractionDefaults.MobileNumber}}',
          name: 'Journey Settings: Mobile Number',
        },
      ]

      if (this.jbSchema.schema && this.jbSchema.schema.length > 0) {
        this.jbSchema.schema.forEach((schemaItem) => {
          // If the key contains spaces, the field name needs to be enclosed in double quotes
          if (schemaItem.key.indexOf(' ') > -1) {
            let fieldName = `"${schemaItem.key.substring(schemaItem.key.lastIndexOf('.') + 1)}"`
            schemaItem.key = `${schemaItem.key.substring(0, schemaItem.key.lastIndexOf('.') + 1)}${fieldName}`
          }

          // Now we prepare a combobox element, taking into account the possibility of having added double quotes
          const aField = {
            expression: `{{${schemaItem.key}}}`,
            key: schemaItem.key,
            name: `Data Extension: ${schemaItem.key.substring(schemaItem.key.lastIndexOf('.') + 1).replaceAll('"', '')}`,
          }
          this.variableItems.push(aField)
        })
      }
    },

    loadDataPhoneFieldItems () {
      this.destinationItems = [
        {
          key: 'MobileNumber',
          expression: '{{InteractionDefaults.MobileNumber}}',
          name: 'Journey Settings: Mobile Number',
        },
      ]

      if (this.jbSchema.schema && this.jbSchema.schema.length > 0) {
        this.jbSchema.schema.forEach((schemaItem) => {
          // If the key contains spaces, the field name needs to be enclosed in double quotes
          if (schemaItem.key.indexOf(' ') > -1) {
            let fieldName = `"${schemaItem.key.substring(schemaItem.key.lastIndexOf('.') + 1)}"`
            schemaItem.key = `${schemaItem.key.substring(0, schemaItem.key.lastIndexOf('.') + 1)}${fieldName}`
          }

          // Now we prepare a combobox element, taking into account the possibility of having added double quotes
          const aField = {
            expression: `{{${schemaItem.key}}}`,
            key: schemaItem.key,
            name: `Data Extension: ${schemaItem.key.substring(schemaItem.key.lastIndexOf('.') + 1).replaceAll('"', '')}`,
          }

          // Only if the field contains the keywords phone or mobile, we add it to this combobox
          if (aField.key.toLowerCase().indexOf('phone') > -1 || aField.key.toLowerCase().indexOf('mobile') > -1) {
            this.destinationItems.push(aField)
          }
        })
      }
    },
  },
}

</script>
