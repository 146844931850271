<template>
  <v-container>
    <v-layout>
      <v-flex>
        Click "More Details"
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'PageRunningHover',
  data: () => ({
  }),

  computed: {
  },
}
</script>
