import * as moment from 'moment'

export default {
    methods: {
        /**
         * Helper function: sort JSON arrays
         */
        sortJSONArray (arrayJSON, key) {
            if (!arrayJSON || arrayJSON.length < 2) {
                return arrayJSON
            }
            return arrayJSON.sort((a, b) => a[key].localeCompare(b[key]))
        },

        /**
         * Helper function: convert a payload from wsProxy DataExtensionObject to a plain object
         */
        wsProxyArrayToPojoArray (data) {
            const objArray = []
            data.data.Results.forEach((result) => {
                const obj = {}
                result.Properties.forEach((resultProperty) => {
                    obj[resultProperty.Name] = resultProperty.Value
                })
                objArray.push(obj)
            })

            return objArray
        },

        formatDate (date, pattern) {
            const res = moment(date).format(pattern)
            // this.$log.debug(date, res)
            return res
        },

        formatDateText (date, inputPattern, outputPattern) {
            const res = moment(date, inputPattern).format(outputPattern)
            // this.$log.debug(date, res)
            return res
        },
    },
}
