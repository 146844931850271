import { createLogger } from 'vue-logger-plugin'

// create logger with options
const logger = createLogger({
    enabled: true,
    callerInfo: true,
    level: process.env.VUE_APP_LOG_LEVEL,
    beforeHooks: [],
    afterHooks: [],
    prefixFormat: ({ level, caller }) => (
        caller
            ? `[${level.toUpperCase()}] [${caller?.fileName}:${caller?.functionName}:${caller?.lineNumber}]`
            : `[${level.toUpperCase()}]`
    )
})
export default logger